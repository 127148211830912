.dark-mode .about-section {
    background-color: rgba(0, 0, 0, 0.2);
}

.about-section {
    display: flex;
    gap: 30px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0px 15%;
    min-height: 700px;
    background-image: url("../images/bg-dark.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}
.download-cv-button {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #4a90e2; /* Bleu désaturé */
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.download-cv-button:hover {
    background-color: #357ab8; /* Bleu un peu plus foncé au survol */
}



.about-section .content {
    text-align: left;
    z-index: 2;

    .social_icons {
        display: flex;
        flex-direction: row;
        gap: 10px;
        color: white;

        svg {
            font-size: 1.8em;
        }
    }

    .mobile_social_icons {
        display: none;
    }

    p {
        font-size: 1.5em;
        margin-block-start: 0px;
        margin-block-end: 0px;
    }

    h1 {
        font-size: 5em;
        margin-block-start: 0px;
        margin-block-end: 0px;
    }
}

.about-section .image-wrapper {
    z-index: 2;
    img {
        width: 150px;
        border-radius: 50%;
    }
}

.about p {
    text-align: center;
    padding-top: 30%;
    padding-bottom: 30%;
}

@media screen {
    @media (max-width: 567px) {
        .nav-left{
            padding-top: 20px;
            float:left;
        }

        .nav-left img {
            height: 35px
        }

        .nav-right {
            float:right;
        }

        .nav-right li {
            padding: 10px 15px;
            list-style:none;
            font-size:13px;
            color:white;
            width: 10%;
        }

        .nav-right ul {
            list-style-type: none;
            margin: 0px;
            padding: 0px;
        }

        body {
            width: 100%;
        }

        .about {
            padding-left: 5%;
            padding-right: 5%;
            font-size: 12px;
        }
    }

    @media (max-width:768px) {
        .about-section h1 {
            font-size: 4em !important;
            text-align: left;
        }

        .about-section p {
            text-align: left;
        }

        .container {
            display: initial;
        }

        .social_icons {
            display: none !important;
        }

        .mobile_social_icons {
            padding-top: 10px;
            display: flex !important;
            flex-direction: row;
            gap: 10px;
            color: white;
    
            svg {
                font-size: 1.8em;
            }
        }

        .about-section {
            padding-top: 150px;
            display: block;
            padding-left: 0px;
            padding-right: 0px;
        }

        .about-section .content {
            width: 90%;
            padding-left: 5%;
            padding-right: 5%;
            padding-bottom: 5%;
        }

        .about-section .image-wrapper {
            padding-left: 5%;
            width: 100%;
        }
    }
}