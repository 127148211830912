.veille-container {
    padding: 5% 10%;
    background-color: var(--background-color); /* Utilise une variable CSS globale */
    color: var(--text-color); /* Utilise une variable CSS globale */
    display: flex;
    flex-direction: column;
    text-align: left;

    h2 {
        font-size: 2.5rem;
        color: var(--text-color); /* Applique la même couleur que dans la section Skills */
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 15px;
        color: var(--text-subtle-color); /* Utilise une variable CSS globale pour le sous-texte */
    }

    .veille-section {
        margin-bottom: 40px;

        h3 {
            font-size: 1.75rem;
            color: var(--text-color); /* Utilise la même couleur que pour le texte principal */
            margin-bottom: 10px;
        }

        .veille-item {
            display: flex;
            align-items: center;
            gap: 15px;
            margin-bottom: 15px;
            padding: 15px;
            background-color: var(--card-background-color); /* Variable pour le fond */
            border-radius: 8px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);

            svg {
                color: var(--link-color); /* Couleur des icônes */
            }

            div {
                p {
                    margin: 5px 0;
                    color: var(--text-color); /* Couleur harmonisée pour le texte */
                }
            }

            a {
                color: var(--link-color); /* Couleur des liens */
                text-decoration: none;
                font-weight: bold;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

/* Mode sombre */
.dark-mode .veille-container {
    background-color: #121212; /* Couleur de fond pour le mode sombre */
    color: #ffffff; /* Couleur du texte pour le mode sombre */

    .veille-item {
        background-color: #1c1c1c; /* Fond des items pour le mode sombre */
        color: #ffffff; /* Couleur du texte des items pour le mode sombre */
    }

    a {
        color: #4ea8de; /* Couleur des liens pour le mode sombre */
    }
}
